$(document).ready(function() {
	// Nav
	var nav = $('.js-nav').on('click', 'button', function(event) {
		$(event.currentTarget).next('ul').toggleClass('is-active');
	});

	// Gallery
    var gallery = $('.js-gallery').magnificPopup({
		delegate: 'a',
		type: 'image',
		gallery: {
			enabled: true
		}
	});

	// Slideshow
	var slideshow = $('.js-slideshow').flexslider({
		selector: 'ul > li',
		directionNav: false,
		controlNav: false,
		slideshow: true,
		slideshowSpeed: 5000,
		animationSpeed: 500
	});

	$('.js-slideshow').on('click', '.slideshow_prev', function(event) {
		event.preventDefault();
		slideshow.flexslider('prev');
	});

	$('.js-slideshow').on('click', '.slideshow_next', function(event) {
		event.preventDefault();
		slideshow.flexslider('next');
	});

	var map = new google.maps.Map($('.js-map')[0], {
		center: {
			lat: 51.151643,
			lng: 3.524905
		},
		zoom: 15
	});

	var marker = new google.maps.Marker({
		position: {
			lat: 51.151643,
			lng: 3.524905
		}, 
		map: map
	});
});